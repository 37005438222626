
export default {
  install(
    Vue,
    options: {
      tags: {
        type: string;
        async?: boolean;
        defer?: boolean;
        attributes: {
          type: string;
          rel?: string;
          href?: string;
          src?: string;
          crossorigin?: string;
        }[]
      }
    }[]
  ) {
    options.tags.forEach(tag => {
      const { async, defer } = tag
      const element = document.createElement(tag.type)
      Object.keys(tag.attributes).forEach(attr => {
        element.setAttribute(attr, tag.attributes[attr])
      })
      if (defer) {
        element.defer = true
      }
      if (async) {
        element.async = true
      }
      document.head.appendChild(element)
    })
  },
}

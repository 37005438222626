import { GetterTree } from 'vuex'
import { UiStateType } from '../types/ui.types'


export const getters: GetterTree<UiStateType, any> = {
  cmsModules: (state) => state.cmsModules,
  isModalOpen: (state) => state.isModalOpen || false as boolean,
  showLoader: (state) => state.showLoader || false as boolean,
  popups:(state) => state.popups,
  panels:(state) => state.panels,
  toasts:(state) => state.toasts,
  miniCart:(state) => state.miniCart,
  menu:(state) => state.menu,
  rtrFullscreenLayerVisible:(state) => state.rtrFullscreenLayerVisible
}

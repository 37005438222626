export const UPDATE_MINICART = 'UPDATE_MINICART'
export const UPDATE_MINICART_SUGGESTIONS = 'UPDATE_MINICART_SUGGESTIONS'
export const UPDATE_MINICART_PROMO = 'UPDATE_MINICART_PROMO'
export const UPDATE_RESET_MINICART = 'UPDATE_RESET_MINICART'

export const mutations = {
  [UPDATE_MINICART]: function(state, payload) {
    state.minicart = payload.data
    state.numberItems = payload.counter
    state.giftCardRemoved = payload.data.giftCardRemovedFlag
  },
  [UPDATE_MINICART_SUGGESTIONS]: function(state, payload) {
    state.suggestionsProduct = payload
  },
  [UPDATE_MINICART_PROMO]: function(state, payload) {
    state.promo = payload
  },
  [UPDATE_RESET_MINICART]: function(state) {
    state.minicart = {}
  },
}

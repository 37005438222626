import { getCurrentCountry } from '../../libs/utils/currentCountry'

export const UPDATE_LOAD_SUGGESTIONS = 'UPDATE_LOAD_SUGGESTIONS'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const UPDATE_LAST_SEARCH_TERM = 'UPDATE_LAST_SEARCH_TERM'
export const UPDATE_RECENTLY_SEARCHED = 'UPDATE_RECENTLY_SEARCHED'
export const UPDATE_REDIRECT_URL = 'UPDATE_REDIRECT_URL'
export const UPDATE_LOAD_SUGGESTED_PRODUCTS = 'UPDATE_LOAD_SUGGESTED_PRODUCTS'
export const UPDATE_SEARCH_OVERLAY_OPEN = 'UPDATE_SEARCH_OVERLAY_OPEN'
export const UPDATE_CLEAR_SUGGESTED_PRODUCTS = 'UPDATE_CLEAR_SUGGESTED_PRODUCTS'
export const UPDATE_LOAD_MORE_SUGGESTED_PRODUCTS = 'UPDATE_LOAD_MORE_SUGGESTED_PRODUCTS'
export const UPDATE_LOAD_MORE_SUGGESTED_CONTENT = 'UPDATE_LOAD_MORE_SUGGESTED_CONTENT'
export const UPDATE_LOAD_SUGGESTED_CONTENT = 'UPDATE_LOAD_SUGGESTED_CONTENT'
export const UPDATE_LOAD_CMS_PLACEMENTS = 'UPDATE_LOAD_CMS_PLACEMENTS'
export const UPDATE_BEST_SELLER = 'UPDATE_BEST_SELLER'
export const UPDATE_POPULAR_LINKS = 'UPDATE_POPULAR_LINKS'
export const UPDATE_NO_RESULT = 'UPDATE_NO_RESULT'
export const UPDATE_TOP_BRANDS = 'UPDATE_TOP_BRANDS'
export const UPDATE_BRAND_NOT_SOLD = 'UPDATE_BRAND_NOT_SOLD'
export const UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH = 'UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH'
export const UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT =
  'UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT'
export const UPDATE_TOGGLE_FAVORITE_FROM_SEARCH = 'UPDATE_TOGGLE_FAVORITE_FROM_SEARCH'
export const UPDATE_QUIZ_SCRIPT_LOADED = 'UPDATE_QUIZ_SCRIPT_LOADED'
export const UPDATE_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT =
  'UPDATE_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT'

import makeDebug from 'debug'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import union from 'lodash/union'
import remove from 'lodash/remove'
import { updateUrl } from '../../libs/utils/url'
import { getSAHingeFilterId } from '../../libs/utils/const'
import { mapFavoriteProducts } from '../../libs/wcs/plp'

const debug = makeDebug('store:mutation')
const hingeFilterIdSA = getSAHingeFilterId()

export const UPDATE_LOAD_PLP_PRODUCTS = 'UPDATE_LOAD_PLP_PRODUCTS'
export const UPDATE_LOAD_PLP_FACETS = 'UPDATE_LOAD_PLP_FACETS'
export const UPDATE_UI = 'UPDATE_UI'
export const UPDATE_UI_BODY = 'UPDATE_UI_BODY'
export const UPDATE_FACETS = 'UPDATE_FACETS'
export const UPDATE_RESET_PAGINATION_DATA = 'UPDATE_RESET_PAGINATION_DATA'
export const UPDATE_NEXT_PAGE_URL = 'UPDATE_NEXT_PAGE_URL'
export const UPDATE_URL = 'UPDATE_URL'
export const UPDATE_IS_FACETS_FIRST_LOAD = 'UPDATE_IS_FACETS_FIRST_LOAD'
export const UPDATE_FACETS_COUNT = 'UPDATE_FACETS_COUNT'
export const UPDATE_CLONE_FILTER_OPTIONS_LIST = 'UPDATE_CLONE_FILTER_OPTIONS_LIST'
export const UPDATE_FILTER_CATEGORY_LABEL = 'UPDATE_FILTER_CATEGORY_LABEL'
export const UPDATE_IDS_FILTERS_CHECKED = 'UPDATE_IDS_FILTERS_CHECKED'
export const UPDATE_REMOVE_FACET_COUNT = 'UPDATE_REMOVE_FACET_COUNT'
export const UPDATE_EXTRAFACETS = 'UPDATE_EXTRAFACETS'
export const UPDATE_FILTERED_CMS_BANNERS = 'UPDATE_FILTERED_CMS_BANNERS'
export const UPDATE_LOAD_PLP_PRODUCTS_VARIANTS = 'UPDATE_LOAD_PLP_PRODUCTS_VARIANTS'
export const UPDATE_RETRIEVE_FAVORITE_LIST = 'UPDATE_RETRIEVE_FAVORITE_LIST'
export const UPDATE_ADD_REMOVE_FAVORITE = 'UPDATE_ADD_REMOVE_FAVORITE'

export const mutations = {
  // update search  results (new search has performed): replace the existing results with new ones
  [UPDATE_LOAD_SUGGESTIONS]: function(state, payload) {
    state.search.results = { ...state.search.results, ...payload }
    state.search.isLoading = false

    const { searchTermToReplace } = payload

    if (searchTermToReplace) {
      const swearWordsRegExp = new RegExp(searchTermToReplace, 'gi') //TODO still needed?

      state.searchTerm = state.searchTerm.replaceAll(swearWordsRegExp, '') //TODO still needed?
    }
  },

  // update search autosuggestions results: replace just the existing products with new ones
  [UPDATE_LOAD_SUGGESTED_PRODUCTS]: function(state, payload) {
    state.search.results.products = [...payload.products]
    state.search.results.currentPage = payload.currentPage
    state.search.results.totalPages = payload.totalPages
    state.search.results.totalProducts = payload.totalProducts
    state.search.results.pageSize = payload.pageSize
    state.search.isLoading = false
  },

  // load more autosuggestions products: append new products to the existing list
  [UPDATE_LOAD_MORE_SUGGESTED_PRODUCTS]: function(state, payload) {
    state.search.results.products = [...state.search.results.products, ...payload.products]
    state.search.results.currentPage = state.facetsSelected.currentPage = payload.currentPage
    state.search.isLoading = false
  },

  [UPDATE_SEARCH_TERM]: function(state, payload) {
    state.searchTerm = payload
  },
  //update in state and persist lastSearchTerm
  [UPDATE_LAST_SEARCH_TERM]: function(state, payload) {
    state.search.lastSearchTerm = payload
    sessionStorage.setItem(
      'searchSession' + '_' + getCurrentCountry(),
      JSON.stringify(state.search)
    )
  },

  [UPDATE_POPULAR_LINKS]: function(state, payload) {
    state.search.popularLinks = payload
  },
  [UPDATE_BEST_SELLER]: function(state, payload) {
    state.search.bestSeller = payload
  },
  [UPDATE_NO_RESULT]: function(state, payload) {
    state.search.noResult = payload
  },

  //save last searched valid term in state and all useful search state in session
  [UPDATE_RECENTLY_SEARCHED]: function(state, { recentlySearchedTermsArray }) {
    state.search.recentlySearched = recentlySearchedTermsArray
    sessionStorage.setItem(
      'searchSession' + '_' + getCurrentCountry(),
      JSON.stringify(state.search)
    )
  },
  [UPDATE_REDIRECT_URL]: function(state, payload) {
    state.search.results.redirectUrl = payload
  },
  [UPDATE_SEARCH_OVERLAY_OPEN]: function(state, payload) {
    state.search.overlayIsOpen = payload
  },
  [UPDATE_CLEAR_SUGGESTED_PRODUCTS]: function(state, payload) {
    state.search.results.products = null
    state.search.results.contentResults = null
    state.search.results.suggestedWords = null
    state.search.results.redirectUrl = ''
  },
  [UPDATE_LOAD_MORE_SUGGESTED_CONTENT]: function(state, payload) {
    state.search.results.contentResults.content = [
      ...state.search.results.contentResults.content,
      ...payload.content,
    ]
    state.search.results.contentResults.paginationInfo = payload.paginationInfo
    state.search.isLoading = false
  },
  [UPDATE_LOAD_SUGGESTED_CONTENT]: function(state, payload) {
    if (payload.content) {
      state.search.results.contentResults.content = payload.content
      state.search.results.contentResults.paginationInfo = payload.paginationInfo
    }
    state.search.isLoading = false
  },
  [UPDATE_LOAD_CMS_PLACEMENTS]: function(state, payload) {
    state.search.cmsPlacements = payload
  },
  [UPDATE_TOP_BRANDS]: function(state, payload) {
    state.search.topBrands = payload
  },
  [UPDATE_BRAND_NOT_SOLD]: function(state, payload) {
    state.search.brandNotSold = payload
  },

  [UPDATE_LOAD_PLP_PRODUCTS]: function(state, payload) {
    debug('%s payload %o', UPDATE_LOAD_PLP_PRODUCTS, payload)
    const { attributes, products, override, ...pagination } = payload
    state.products = override ? [...products] : [...state.products, ...products]
    state.attributes = Object.assign({}, state.attributes, attributes)
    state.pagination = Object.assign({}, state.pagination, pagination)
  },
  //used with algolia to delay ungrouped call
  [UPDATE_LOAD_PLP_PRODUCTS_VARIANTS]: function(state, payload) {
    const { products } = payload
    state.products = [...products]
  },
  [UPDATE_LOAD_PLP_FACETS]: function(state, payload) {
    debug('%s payload %o', UPDATE_LOAD_PLP_FACETS, payload)
    state.facets = Object.assign({}, state.facets, { ...payload })
  },
  [UPDATE_UI]: function(state, payload) {
    if (find(state.facets.filterOptionsList, { groupName: 'brand' })) {
      const brandList = find(state.facets.filterOptionsList, { groupName: 'brand' }).list
      if (!isEmpty(brandList)) {
        find(state.facets.filterOptionsList, { groupName: 'brand' }).list = sortBy(
          brandList,
          'facetIdentifier'
        )
      }
    }
    debug('%s payload %o', UPDATE_UI, payload)
    state.ui = Object.assign({}, state.ui, { ...payload })
  },
  [UPDATE_FACETS]: function(state, payload) {
    state.facetsSelected = Object.assign({}, state.facetsSelected, { ...payload })
    sessionStorage.setItem(
      'searchSessionFilters' + '_' + getCurrentCountry(),
      JSON.stringify(state.facetsSelected)
    )
  },
  //#SGHDP-9070 reset pagination data from endpoint to previous values
  [UPDATE_RESET_PAGINATION_DATA]: function(state, payload) {
    const { ...pagination } = payload
    if (parseInt(pagination.currentPage) > 1) {
      state.search.results.pageSize = parseInt(
        pagination.pageSize / state.facetsSelected.currentPage
      )
      state.search.results.totalPages = Math.ceil(
        pagination.totalProducts / state.search.results.pageSize
      )
    }
  },
  //TODO all the "next page url" flow (action and mutation) is pointless, state.nextPageURL is never used, can be removed entirely
  [UPDATE_NEXT_PAGE_URL]: function(state, payload) {
    state.nextPageURL = payload.nextPageURL
  },
  [UPDATE_URL]: function(state, { idsFiltersCheckedList, ...rest }) {
    //TODO is this still needed? currentParams gets overwritten anyway
    const currentParams = qs.parse(decodeURIComponent(window.location.search), {
      ignoreQueryPrefix: true,
    })

    if (state.facetsSelected.extraFacets)
      idsFiltersCheckedList = union([...state.facetsSelected.extraFacets, ...idsFiltersCheckedList])
    const { currentPage, orderBy, clearUrl } = rest

    //TODO we shouldn't reset state in an update url function, a dedicated mutation should do it
    if (clearUrl) {
      idsFiltersCheckedList = []
      state.facetsSelected.idsFiltersCheckedList = []
      currentParams['currentPage'] = 1
      currentParams['facet'] = []
    } else {
      idsFiltersCheckedList.length > 0
        ? (currentParams['facet'] = idsFiltersCheckedList)
        : (currentParams['facet'] = [])
      if (orderBy && orderBy != 'default') currentParams['orderBy'] = orderBy
      currentPage != 1 && currentPage !== undefined
        ? (currentParams['currentPage'] = currentPage)
        : (currentParams['currentPage'] = 1)
    }

    updateUrl(currentParams)
  },
  [UPDATE_IS_FACETS_FIRST_LOAD]: function(state, payload) {
    state.isFacetsFirstLoad = payload
  },
  [UPDATE_FACETS_COUNT]: function(state, payload) {
    // creates an object with all the facets ids as keys
    // and the filters items count as value, we are using it
    // to check if the filters should be visible on first load
    let outputFacets = {}
    if (payload.facet?.filterOptionsList) {
      payload.facet.filterOptionsList.forEach(function(group) {
        if (group.list) {
          group.list.forEach(function(filter) {
            outputFacets[filter.id] = filter.count
          })
        }
      })
    }
    state.facetsCount = outputFacets
  },
  [UPDATE_UI_BODY]: function(state, payload) {
    const key = Object.keys(payload)
    key.forEach(e => {
      document.body.style[e] = payload[e]
    })
    state.ui.body = Object.assign({}, state.ui.body, payload)
  },
  //TODO check if https://bitbucket.org/luxottica_rona/sgh-static-ui/pull-requests/289 is still needed and if there's a better way to handle this
  //see also https://bitbucket.org/luxottica_rona/sgh-static-ui/pull-requests/?state=MERGED&query=10328
  [UPDATE_CLONE_FILTER_OPTIONS_LIST]: function(state, payload) {
    state.isFilterCategoryHidden = JSON.parse(JSON.stringify(payload))
  },
  // end SGHDP-10243
  [UPDATE_FILTER_CATEGORY_LABEL]: function(state, payload) {
    state.isFilterCategoryLabel = payload
  },
  [UPDATE_IDS_FILTERS_CHECKED]: function(state, payload) {
    const { id, reset, resetSort = false } = payload
    if (reset) {
      // state.facetsSelected.idsFiltersCheckedList =  state.facetsSelected.idsFiltersCheckedList.splice(0, state.facetsSelected.idsFiltersCheckedList.length)
      state.facetsSelected.idsFiltersCheckedList = []
      state.facetsSelected.currentPage = 1
      if (resetSort === true) state.facetsSelected.orderBy = 'default'
      sessionStorage.removeItem('searchSessionFilters' + '_' + getCurrentCountry())
    } else {
      //if user interacts with a price interval filter in UI, remove custom price filter from extrafacet
      if (id.substr(0, 5) === 'price') {
        remove(state.facetsSelected.extraFacets, facet => {
          return facet.substr(0, 5) === 'price'
        })
      }
      //remove hinge extrafacet when toggled off
      if (id.includes(hingeFilterIdSA)) {
        remove(state.facetsSelected.extraFacets, facet => {
          return facet.includes(hingeFilterIdSA)
        })
      }

      //if there's a click on facets:  add it ot remove from our list of checked filter
      let indexOf = state.facetsSelected.idsFiltersCheckedList.indexOf(id)
      if (indexOf > -1) {
        state.facetsSelected.idsFiltersCheckedList.splice(indexOf, 1)
      } else {
        state.facetsSelected.idsFiltersCheckedList.push(id)
      }
    }
  },
  [UPDATE_REMOVE_FACET_COUNT]: function(state, id) {
    // remove a count=0 filter from the object because has
    // become available again
    delete state.facetsCount[id]
  },
  [UPDATE_EXTRAFACETS]: function(state, extraFacets) {
    state.facetsSelected.extraFacets = extraFacets
  },
  [UPDATE_FILTERED_CMS_BANNERS]: function(state, payload) {
    state.filteredCMSBanners = payload
  },
  [UPDATE_RETRIEVE_FAVORITE_LIST]: function(state, payload) {
    const wishlistProducts = payload.wishlistItems.products || []
    wishlistProducts.forEach(wishlistProduct => {
      const product = state.products.find(p => p.catentryId === wishlistProduct.catentryId)
      if (product) {
        product.wishlistRelatedLinks = wishlistProduct.wishlistRelatedLinks
        product.wishlistRelatedLinks['isInWishlist'] = true
      }
    })
  },
  [UPDATE_ADD_REMOVE_FAVORITE]: function(state, payload) {
    const productToUpdate = state.products?.find(e => e.catentryId === payload.catentryId)
    if (productToUpdate) {
      productToUpdate.wishlistRelatedLinks.isInWishlist = payload.isInWishlist
    }
  },
  [UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH]: function(state, payload) {
    const wishlistProducts = payload.wishlistItems.products || []
    wishlistProducts.forEach(wishlistProduct => {
      const product = state.search.results.products?.find(
        p => p.catentryId === wishlistProduct.catentryId
      )
      if (product) {
        product.wishlistRelatedLinks = wishlistProduct.wishlistRelatedLinks
        product.wishlistRelatedLinks['isInWishlist'] = true
      }
    })
  },
  [UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT]: function(state, payload) {
    const wishlistProducts = payload.wishlistItems.products || []
    wishlistProducts.forEach(wishlistProduct => {
      const product = state.search.noResult.find(p => p.catentryId === wishlistProduct.catentryId)
      if (product) {
        product.wishlistRelatedLinks = wishlistProduct.wishlistRelatedLinks
        product.wishlistRelatedLinks['isInWishlist'] = true
      }
    })
  },

  [UPDATE_TOGGLE_FAVORITE_FROM_SEARCH]: function(state, payload) {
    const productToUpdate = state.search.results.products?.find(
      e => e.catentryId === payload.catentryId
    )
    if (productToUpdate) {
      productToUpdate.wishlistRelatedLinks.isInWishlist = !productToUpdate.wishlistRelatedLinks
        .isInWishlist
    }
  },

  [UPDATE_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT]: function(state, payload) {
    const productToUpdate = state.search.noResult.find(e => e.catentryId === payload.catentryId)
    if (productToUpdate) {
      productToUpdate.wishlistRelatedLinks.isInWishlist = !productToUpdate.wishlistRelatedLinks
        .isInWishlist
    }
  },

  [UPDATE_QUIZ_SCRIPT_LOADED]: function(state, payload) {
    state.search.quizScriptLoaded = payload
  },
}

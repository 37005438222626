import { Commit } from 'vuex'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { getCMSPlacements  } from '../../libs/wcs/cms'
import { safeJSONParse } from '../../libs/utils/string'
import { UPDATE_CMSPLACEMENTS } from '../mutations/ddm'


export const ACTION_UPDATE_CMSPLACEMENTS = 'ACTION_UPDATE_CMSPLACEMENTS'

function parseDDMData(data) {
  const safeData = data.content
    .replace(/\\u003C|\\u003E/g, '')
    .replace(/\\&quot;/g, '\'')
    .replace(/,\s*}/g, '}')
    .replace(/,\s*]/g, ']')

  const cleanData = safeJSONParse(safeData, 'DDM')

  cleanData.mainMenu = cleanData.mainMenu.map((item) => {
    if (item.subMenu?.menuLists[0].items.length > 12) {
      let list = item.subMenu.menuLists[0].items
      let lists = []
      const label = item.subMenu.menuLists[0].label

      let isFirst = true
      while (list.length) {
        lists.push({
          items: list.splice(0, 12),
          label: isFirst ? label : null,
        })
        isFirst = false
      }

      item.subMenu.menuLists = lists
    }
    return item
  })

  return cleanData
}

export const actions = {
  [ACTION_UPDATE_CMSPLACEMENTS]: async function ({ commit }: { commit: Commit }, param: any) {
    try {
      const name = 'ddmPlacement'
      const device = param?.view === 'asNavigationDtMain' ? 'desktop' : 'mobile'

      const ddmName = `${name}_${device}${window.algoliaConfig.isEarlyAccessParam ? 'EA' : ''}`
      const contentType = 'application/json'
      const axiosDataType = contentType === 'application/json' ? {} : null
      let data =
        safeJSONParse(sessionStorage.getItem(`${ddmName}_${getCurrentCountry()}`)) ||
        (await getCMSPlacements(
          {
            ...param,
            headers: {
              'Content-Type': contentType,
            },
            axiosDataType,
          },
          ddmName
        ))

      if (data) {
        const cleanData = parseDDMData(data)
        commit(UPDATE_CMSPLACEMENTS, cleanData.mainMenu)

        return data
      }
    } catch (error) {
      console.error(error)
    }
  },
}

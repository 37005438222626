import { actions } from '../actions/minicart'
import { getters } from '../getters/minicart'
import { mutations } from '../mutations/minicart'

export const minicart = {
  namespaced: true,
  state: {
    minicart: {},
    numberItems: {},
    giftCardRemoved: false,
    suggestionsProduct: {},
    promo: {},
  },
  actions,
  getters,
  mutations,
}
